.teamContainer {
  position: relative;
  cursor: pointer;
}

.teamMenuLG {
  position: absolute;
  top: 70px;
  left: -15px;
  width: 180px;
  display: none;
  justify-content: center;
  padding: 10px;
  background-color: #2d4859;
}

.teamContainer:hover .teamMenuLG {
  display: flex;
}

.teamMenu {
  display: none;
  width: 160px;
  margin-bottom: 16px;
}

/* responsive style */
@media only screen and (max-width: 992px) {
  .teamMenuLG {
    display: none;
  }
  .teamContainer:hover .teamMenu {
    display: block;
  }
}
/* responsive style */
